import "./App.css";

const Body = () => (
  <>
    <h1 className="header-name">
      broke<br></br>bldgs broke<br></br>bldgs broke<br></br>bldgs broke<br></br>
      bldgs broke<br></br>bldgs broke<br></br>bldgs broke<br></br>bldgs
    </h1>
  </>
);

export default Body;
